<template>
  <div>
    <Modal :options="{close: true, width: '80vw'}" @close="$emit('close')">
      <div class="title">Ticket: {{ ticket.ticket }}</div>
      <div class="body">
        <div class="ticket_cols">
          <div class="row">
            <div class="col-sm-7">
              <div class="container ticket">
                <div class="row">
                  <label class="col-form-label col-sm-3 info-title">Detalles:</label>
                  <div class="col-sm-9 info-content" v-html="$helper.nl2br(ticket.descripcion)"></div>
                </div>
                <div v-if="ticket.razon_rechazo" class="row mt-2">
                  <label class="col-form-label col-sm-3 info-title">Razón de rechazo:</label>
                  <div class="col-sm-9 info-content" v-html="$helper.nl2br(ticket.razon_rechazo)"></div>
                </div>
              </div>
              <hr>
              <div class="container observaciones">
                <div class="row">
                  <div class="col-sm-12 section_title">Comentarios</div>
                </div>
                <template v-if="ticket.comentarios.length > 0">
                <div v-for="(comentario, index) in ticket.comentarios" class="row observacion" :key="index">
                    <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-12 comentario" v-html="comentario.comentario"></div>
                        </div>
                        <div class="row info">
                            <div class="col-sm-8">De: {{ comentario.nombre }}</div>
                            <div class="col-sm-4 text-right">{{ $moment(comentario.created_at).format('ddd DD MMM YYYY HH:mm')}}</div>
                        </div>
                    </div>
                </div>
                </template>
                <div v-else class="observaciones">
                  <div class="row">
                    <div class="col-sm-12">No hay comentarios</div>
                  </div>
                </div>
              </div>
              <div v-if="ticket_no_viable" class="agregar_observaciones mt-3">
                <form @submit.prevent="agregar_comentarios">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="input-group mb-3">
                        <input v-model="comentario" type="text" class="form-control" placeholder="Comentario...">
                        <div class="input-group-append">
                          <button class="btn btn-secondary">Agregar comentario</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 section_title text-right">Información</div>
                </div>
                <div class="informacion">
                  <div class="row">
                    <label class="col-form-label col-sm-4 info-title">Usuario asignado:</label>
                    <div class="col-sm-8 info-content">{{ ticket.nombre }}</div>
                  </div>
                  <div class="row">
                    <label class="col-form-label col-sm-4 info-title">Prioridad:</label>
                    <div class="col-sm-8 info-content">{{ ticket.prioridad }}</div>
                  </div>
                  <div class="row">
                    <label class="col-form-label col-sm-4 info-title">Creación:</label>
                    <div class="col-sm-8 info-content">{{ $moment(ticket.created_at).format('ddd DD MMM YYYY HH:mm') }}</div>
                  </div>
                  <div class="row">
                    <label class="col-form-label col-sm-4 info-title">Etapa</label>
                    <div class="col-sm-8 info-content">{{ ticket.etapas.length > 0 ? ticket.etapas[0].etapa.etapa : 'N/A' }}</div>
                  </div>
                  <div class="row">
                    <label class="col-form-label col-sm-4 info-title">Tiempo en etapa:</label>
                    <div class="col-sm-8 info-content">{{ tiempo_etapa }}</div>
                  </div>
                </div>
                <div class="documentos">
                  <div class="row">
                    <div class="col-sm-12 section_title text-rigth">Documentos</div>
                  </div>
                  <ul v-if="ticket.documentos.length > 0">
                    <li v-for="(documento,index) in ticket.documentos" :key="index">
                      <a href="#" :title="documento.nombre_original" @click.prevent="descargar_documento(ticket.id, documento)">{{ documento.nombre_original.length > 33 ? documento.nombre_original.substr(0,30)+'...' : documento.nombre_original }} [{{ $helper.bytesConverted(documento.peso) }}]</a>
                    </li>
                  </ul>
                  <div v-else class="row">
                    <div class="col-sm-12 text-right">No hay documentos</div>
                  </div>
                  <div v-if="ticket_no_viable" class="download_input mt-3 text-right">
                    <button class="btn btn-secondary" @click="$refs.cargar_archivo.click()">Cargar documento</button>
                    <input type="file" name="cargar_archivo" id="cargar_archivo" ref="cargar_archivo" @change="cargar_archivo">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/Modal';

  import apiTickets from '@/apps/constelacion/api/tickets'

  export default {
    components: {
      Modal
    }
    ,props: {
      org_ticket: {
        type: Object
        ,default() {
          return {
            id: null
            ,usuario_id: null
            ,nombre: 'Usuario prueba'
            ,prioridad: 'baja'
            ,detalles: 'Prueba\nEsta es una prueba'
            ,documentos: [
              {
                nombre_original: 'Archivo prueba'
                ,peso: 213258
              },
              {
                nombre_original: 'Archivo prueba 2'
                ,peso: 4581568
              }
            ]
          }
        }
      }
    }
    ,data() {
      return {
        realheight: 0,
        ticket: {
          id: null
          ,usuario_id: null
          ,nombre: 'Usuario prueba'
          ,prioridad: 'baja'
          ,detalles: 'Prueba\nEsta es una prueba'
          ,etapas: []
          ,documentos: [
            {
              nombre_original: 'Archivo prueba'
              ,peso: 213258
            },
            {
              nombre_original: 'Archivo prueba 2'
              ,peso: 4581568
            }
          ]
          ,comentarios: []
        }
        ,comentario: null
      }
    }
    ,mounted() {
      this.obtener_ticket();
    }
    ,methods: {
      async obtener_ticket() {
        try {
          this.ticket = (await apiTickets.obtener_ticket(this.org_ticket.id)).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },
      async descargar_documento(ticket, documento) {
        try {
          let archivo = (await apiTickets.descargar_documento(ticket, documento.id)).data;

          this.$helper.descargar_documento(archivo, documento.nombre_original);
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },
      async cargar_archivo() {
        try {
          if (this.$refs.cargar_archivo.files.length == 0)
            return;

          let formData = new FormData();
          formData.append('documentos[0]', this.$refs.cargar_archivo.files[0]);

          let res = (await apiTickets.cargar_documento(this.ticket.id, formData)).data;
          this.$log.info('res', res);
          this.$refs.cargar_archivo.value = null;

          this.obtener_ticket();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async agregar_comentarios() {
        try {
          let res = (await apiTickets.crear_comentario(this.ticket.id, {comentario: this.comentario})).data;
          this.$log.info('res', res);

          this.obtener_ticket();
          this.comentario = null;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,ult_etapa_activa(ticket) {
        let etapas = [];

        ticket.etapas.forEach(etapa => {
          if (etapa.estatus == 1)
            etapas.push(etapa)
        });

        if (etapas.length == 0)
          etapas.push(ticket.etapas[0]);

        etapas.sort((a,b) => {
          return a.posicion < b.posicion ? -1 : 1;
        })

        return etapas[0];
      }
      ,ticket_no_viable() {
        let etapa = ult_etapa_activa(ticket)

        if (!etapa)
          return false;

        return etapa.etapa.tipo != 2;
      }
    }
    ,computed: {
      calcular_tiempo_etapa() {
        if (this.ticket.etapas.length == 0)
          return 'N/A';
        return this.$helper.diff_human(this.ticket.etapas[0].etapa.created_at, 'now');
      }
      ,tiempo_etapa() {
        let etapa = this.ult_etapa_activa(this.ticket);

        if (!etapa)
          return 'N/A';

        return this.$helper.diff_human(etapa.created_at, 'now', true);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .section_title {
    background-color: #4c4c4c;
    font-size: 1.5em;
    color: #fff;
    padding: 5px 10px;
  }

  .ticket {
    .info-title {
      background-color: #CCCCCC;
    }

    .info-content {
      background-color: #E6E6E6;
      padding: 5px;
    }
  }

  .observaciones {
    .comentario {
      padding: 5px 10px;
    }

    .info {
      background-color: #e0e0e0;
      color: #666;
      font-size: 0.75em;
      padding: 5px 10px;
    }
  }

  .informacion {
    .row {
      margin-bottom: 5px;

      .info-title {
        background-color: #CCCCCC;
      }

      .info-content {
        background-color: #E6E6E6;
        text-align: right;
        padding: 5px;
      }
    }
  }

  .documentos {
    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        margin: 0px;
        padding: 5px 10px;
        text-align: right;
      }

      li:nth-child(2n+0) {
        background-color: #E6E6E6;
      }
    }

    input[type="file"] {
      display: none;
    }
  }
</style>